import React from 'react';
import { Facebook, Instagram, Linkedin, Youtube } from 'lucide-react';
import { useStore } from '../../context/StoreContext';
import SkeletonLoader from '../SkeletonLoader';

const Banner = () => {
  const { storeDetails, loading, error } = useStore();

  if (loading) {
    return <SkeletonLoader type={'banner'} />;
  }

  if (error) {
    return <div className="text-center text-red-500">Error loading store details</div>;
  }

  return (
    <div className="text-center mb-8">
      <div className="inline-block rounded-full p-1 overflow-hidden mb-8 border-2 border-secondary shadow-lg">
        <img src={storeDetails?.store_logo} alt="Profile" className="w-32 h-32 object-cover rounded-full" />
      </div>
      <div className="max-w-4xl mx-auto px-8 py-6 border border-gray-200 rounded-lg shadow-sm relative">
        <h1 className="text-4xl font-bold text-gray-800 top-[-47px] inline-block bg-white px-5 relative">{storeDetails?.store_name}</h1>
        <p className="text-gray-600 leading-relaxed">
          Welcome to Bark Avenue Grooming, where your furry, feathered, and finned friends are our top priority! We believe
          pets are family, and we're passionate about providing everything you need to keep them happy, healthy, and thriving.
          From nutritious food and tasty treats to cozy beds and engaging toys, our carefully curated selection caters to every
          pet's unique needs. Shop our favorites!
        </p>
        <div className='flex justify-center'>
          <div className="flex justify-center width-max-content space-x-6 relative bg-white px-5 -bottom-[40px]">
            <Facebook className="w-8 h-8 text-gray-400 border border-slate-950 border-2 rounded-md p-1" />
            <Instagram className="w-8 h-8 text-gray-400 border border-slate-950 border-2 rounded-md p-1" />
            <Linkedin className="w-8 h-8 text-gray-400 border border-slate-950 border-2 rounded-md p-1" />
            <Youtube className="w-8 h-8 text-gray-400 border border-slate-950 border-2 rounded-md p-1" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;