import React, { useState, useEffect } from 'react';
import { ShoppingCart, Share } from 'lucide-react';
import { useStore } from '../../context/StoreContext';
import CartView from '../CartView';
import { Link, useNavigate } from 'react-router-dom';

const Header = () => {
    const { storeDetails, cartItems, error } = useStore();
    const navigate = useNavigate();
    const [isCartOpen, setIsCartOpen] = useState(false);
    const [hasCartItems, setHasCartItems] = useState(false);
    useEffect(() => {
        setHasCartItems(cartItems?.length > 0);
    }, [cartItems]);

    const handleCartClick = () => {
        setIsCartOpen(true);
    };

    const handleClose = () => {
        setIsCartOpen(false);
    }

    useEffect(() => {
        // navigate('/store-not-available')
    }, [error])
    return (
        <>
            <header className="bg-gray-800 text-white p-4 flex justify-between items-center sticky top-0 z-10">
                <Link to="/">
                    <h1 className="text-xl font-bold">{storeDetails?.store_name}</h1>
                </Link>
                <div className="flex items-center space-x-4">
                    <div className="relative cursor-pointer" onClick={handleCartClick}>
                        <ShoppingCart className="mr-2" />
                        {hasCartItems && (
                            <span className="absolute -top-1 -right-1 bg-red-500 rounded-full h-2 w-2"></span>
                        )}
                    </div>
                    <Share />
                </div>
            </header>
            <CartView isOpen={isCartOpen} onClose={handleClose} />
        </>
    );
};

export default Header;