import React, { useState, useEffect } from 'react';
import Banner from '../../components/Banner';
import ProductCard from '../../components/ProductCard';
import SkeletonLoader from '../../components/SkeletonLoader';
import { useStore } from '../../context/StoreContext';
import axios from '../../utilities/axios';
import * as API_END_POINT from '../../utilities/constant';

const Home = () => {
    const { storeDetails } = useStore();
    const [products, setProducts] = useState([]);
    const [loadingProducts, setLoadingProducts] = useState(true); // loading state for products

    useEffect(() => {
        if (storeDetails?.store_name) {
            getStoreProducts();
        }
    }, [storeDetails]);

    const getStoreProducts = async () => {
        setLoadingProducts(true);
        try {
            let query = {};
            let filter = [];

            const payload = {
                paging: { limit: 96, offset: 0 },
                query: query,
                filter: filter,
            };
            const response = await axios.post(API_END_POINT.STORE_PRODUCTS, payload);
            setProducts(response.data.data.rows);
        } catch (err) {
            console.error('Error fetching products', err);
        } finally {
            setLoadingProducts(false);
        }
    };

    return (
        <main className="container mx-auto px-8 py-8">
            <Banner />
            <div className="mb-8">
                <nav className="flex justify-center">
                    {storeDetails?.StorefrontCategories?.map((category) => (
                        <button className="px-4 py-2 font-semibold text-gray-800" key={category?.id}>
                            {category?.name}
                        </button>
                    ))}
                </nav>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {loadingProducts
                    ? Array.from({ length: 8 }).map((_, index) => (
                        <SkeletonLoader type="productCard"  key={index} />
                    ))
                    : products.map((product) => (
                        <ProductCard key={product.id} product={product} />
                    ))}
            </div>

            <div className="text-center mt-8">
                <button className="text-gray-300 px-6 py-2 rounded-full font-semibold">
                    WANT TO SEE MORE?
                </button>
            </div>
        </main>
    );
};

export default Home;
