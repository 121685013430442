import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import axios from '../../utilities/axios';
import * as API_END_POINT from '../../utilities/constant';

const Skeleton = ({ className }) => (
  <div className={`animate-pulse bg-gray-200 rounded ${className}`}></div>
);

const OrderDetails = () => {
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        setLoading(true);
        const response = await axios.post(API_END_POINT.STORE_ORDER_DETAILS(id), {});
        if (response && response.data && response.data.data) {
          setOrderDetails(response.data.data[0]);
        } else {
          throw new Error('No data received from the server');
        }
      } catch (err) {
        setError(err.message || 'An error occurred while fetching the order details');
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();
  }, [id]);

  const formatDate = (date) => moment(date).format('MM/DD/YYYY');
  const twoDecimal = (num) => parseFloat(num).toFixed(2);
  const toFourDigit = (number) => number.toString().padStart(4, '0');
  const EllipsisText = (text, maxLength) => {
    // Determine if the text needs to be truncated
    const shouldTruncate = text.length > maxLength;
    const displayText = shouldTruncate ? text.slice(0, maxLength) + '...' : text;

    return displayText
  }
  const OrderSkeleton = () => (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <Skeleton className="h-8 w-1/4 mb-6" />

      <div className="grid grid-cols-3 gap-4 mb-6">
        {[...Array(3)].map((_, index) => (
          <div key={index}>
            <Skeleton className="h-4 w-1/2 mb-2" />
            <Skeleton className="h-6 w-3/4" />
          </div>
        ))}
      </div>

      {[...Array(2)].map((_, index) => (
        <div key={index} className="border rounded-lg p-4 mb-6">
          <div className="grid grid-cols-5 gap-4 mb-4">
            {[...Array(5)].map((_, i) => (
              <div key={i}>
                <Skeleton className="h-4 w-3/4 mb-2" />
                <Skeleton className="h-6 w-1/2" />
              </div>
            ))}
          </div>

          <div className="bg-gray-100 p-4 rounded-lg flex items-start space-x-4 mb-4">
            <Skeleton className="w-12 h-12 rounded" />
            <div className="flex-grow">
              <Skeleton className="h-6 w-3/4 mb-2" />
              <Skeleton className="h-4 w-1/2" />
            </div>
            <Skeleton className="h-6 w-20" />
          </div>

          <Skeleton className="h-4 w-full" />
        </div>
      ))}
    </div>
  );

  if (loading) {
    return <OrderSkeleton />;
  }

  if (error) {
    return <div className="text-center text-red-600 p-4">{error}</div>;
  }

  if (!orderDetails) {
    return <div className="text-center p-4">No order details found.</div>;
  }

  return (
    <div className="max-w-5xl mx-auto p-6 bg-white rounded-lg">
      <h1 className="text-2xl font-bold mb-6">Order</h1>

      <div className="flex flex-wrap items-start gap-x-8 gap-y-2 mb-6">
        <div>
          <p className="text-lg text-gray-600 mb-1">Order ID</p>
          <p className="font-semibold">#{toFourDigit(orderDetails.retailer_order_id)}</p>
        </div>
        <div>
          <p className="text-lg text-gray-600 mb-1">Order Date</p>
          <p className="font-semibold">{formatDate(orderDetails.created_at)}</p>
        </div>
        <div className="flex-grow">
          <p className="text-lg text-gray-600 mb-1">Shipping address</p>
          <p className="font-semibold">
            {orderDetails.order_shipping_addresses?.[0]?.shipping_address_1},{' '}
            {orderDetails.order_shipping_addresses?.[0]?.shipping_state},{' '}
            {orderDetails.order_shipping_addresses?.[0]?.shipping_country}
          </p>
        </div>
      </div>

      {orderDetails.order_brands?.map((brand, index) => (
        <div key={index} className="border rounded-lg p-4 mb-6">
          <div className="grid grid-cols-3 gap-4 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-6 mb-4">
            <div>
              <p className="text-md text-gray-600 mb-1">Brand name</p>
              <p className="font-semibold">  {EllipsisText(brand?.store_name, 14)}</p>
            </div>
            <div>
              <p className="text-md text-gray-600 mb-1">Subtotal</p>
              <p className="font-semibold">${twoDecimal(brand.msrp_sub_total)}</p>
            </div>
            {
              brand?.discount_sub_total ?
                <div>
                  <p className="text-md text-gray-600 mb-1">Discount</p>
                  <p className="font-semibold">${twoDecimal(brand.discount_sub_total)}</p>
                </div>
                : null
            }
            <div>
              <p className="text-md text-gray-600 mb-1">Shipping</p>
              <p className="font-semibold">${twoDecimal(brand.shipping_cost_sub_total)}</p>
            </div>
            <div>
              <p className="text-md text-gray-600 mb-1">Tax</p>
              <p className="font-semibold">${twoDecimal(brand.tax_amount_sub_total || 0)}</p>
            </div>
            <div>
              <p className="text-md text-gray-600 mb-1">Total</p>
              <p className="font-semibold">${twoDecimal(brand.order_sub_total - (brand.discount_sub_total || 0))}</p>
            </div>
          </div>

          {brand.order_brand_items.map((item, i) => (
            <div key={i} className="bg-gray-100 p-4 rounded-lg flex items-start space-x-4 mb-4">
              <img
                src={item.product_variants?.image || item.products?.product_images[0]?.src || "/api/placeholder/48/48"}
                alt={item.products?.title}
                className="max-w-20	 object-cover rounded"
              />
              <div className="flex-grow">
                <h3 className="font-semibold text-lg">{item.products?.title}</h3>
                <p className="text-sm text-gray-600">
                  {item?.product_variants?.title !== "Default Title" && (<div className="quintity"><span className="quintity-value"><strong>({item?.product_variants?.title})</strong></span></div>)}
                </p>
                <p className="text-sm text-gray-600">Quantity: {item.quantity}</p>
                {item?.customizable_note &&
                  <p className="text-sm text-gray-600">
                    Customized tag Name: <span className="note">{item?.customizable_note}</span></p>
                }
              </div>
              <div className="text-right">
                {brand?.discount_prcnt ? (
                  <div className="price strike-price">
                    <s>${twoDecimal(item?.msrp_per_item)}</s>{" "}
                    <span className='discount-price text-green-700 font-semibold'>
                      ${twoDecimal(item?.msrp_per_item - (item?.msrp_per_item * (brand?.discount_prcnt / 100)))}
                    </span>
                  </div>
                ) : (
                  <div className="price text-green-700 font-semibold">
                    ${twoDecimal(item?.msrp_per_item - (item?.msrp_per_item * (brand?.discount_prcnt / 100)))}
                  </div>
                )}
              </div>
            </div>
          ))}

          <p className="text-sm text-gray-600">
            Contact <span className="font-semibold">{brand.store_name}</span> at{' '}
            <a href={`mailto:${brand.company_email_address}`} className="font-semibold text-prm hover:underline">
              {brand.company_email_address}
            </a>{' '}
            regarding questions or changes to your order.
          </p>
        </div>
      ))}
    </div>
  );
};

export default OrderDetails;